import React, { useState, useEffect } from 'react';
import { jwtDecode } from 'jwt-decode';
import MapView from './components/Map/map-view';
import Login from './components/Login/login';

function App() {
  const [token, setToken] = useState(null);

  useEffect(() => {
    const storedToken = localStorage.getItem('token');
    if (storedToken) {
      try {
        const decodedToken = jwtDecode(storedToken);
        const currentTime = Date.now() / 1000;
        if (decodedToken.exp > currentTime) {
          setToken(storedToken);
        } else {
          localStorage.removeItem('token');
        }
      } catch (error) {
        console.error('Erro ao decodificar o token:', error);
        localStorage.removeItem('token');
      }
    }
  }, []);

  const handleLoginSuccess = (token) => {
    setToken(token);
  };

  const handleLogout = () => {
    setToken(null);
    localStorage.removeItem('token');
  };

  return (
    <div className="App">
      {!token ? (
        <Login onLoginSuccess={handleLoginSuccess} />
      ) : (
        <MapView onLogout={handleLogout} />
      )}
    </div>
  );
}

export default App;
