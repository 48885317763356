// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.spinner {
  animation: spin 1s linear infinite;
  width: 50px;
  bottom:5px;
  right: 5px;
  position: absolute;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
`, "",{"version":3,"sources":["webpack://./src/components/LoadingSpinner/loading-spinner.css"],"names":[],"mappings":"AAAA;EACE,kCAAkC;EAClC,WAAW;EACX,UAAU;EACV,UAAU;EACV,kBAAkB;AACpB;;AAEA;EACE,KAAK,uBAAuB,EAAE;EAC9B,OAAO,yBAAyB,EAAE;AACpC","sourcesContent":[".spinner {\n  animation: spin 1s linear infinite;\n  width: 50px;\n  bottom:5px;\n  right: 5px;\n  position: absolute;\n}\n\n@keyframes spin {\n  0% { transform: rotate(0deg); }\n  100% { transform: rotate(360deg); }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
