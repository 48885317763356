import React, { useEffect, useState, useCallback, useRef } from 'react';
import { MapContainer, TileLayer, Marker, Popup, Polyline, ZoomControl } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import 'leaflet-rotatedmarker';
import axios from 'axios';
import DatePicker, { registerLocale } from 'react-datepicker';
import ptBR from 'date-fns/locale/pt-BR';
import 'react-datepicker/dist/react-datepicker.css';
import './map-view.css';
import '../LoadingSpinner/loading-spinner.css';
import LoadingSpinner from '../LoadingSpinner/loading-spinner.js';
import { FaCalendarAlt, FaSpinner } from 'react-icons/fa';
import icon from 'leaflet/dist/images/marker-icon.png';
import logo from '../../assets/logo-transparent-small.png';
import { jwtDecode } from 'jwt-decode';
import last_marker_ico from '../../assets/icons/icons8-marker-50.png';
import route_arrow_ico from '../../assets/icons/right-arrow.png';


let DefaultIcon = L.icon({
    iconUrl: icon,
    iconSize: [13, 22],
    iconAnchor: [6, 20]
});

let LastPointIcon = L.icon({
  iconUrl: last_marker_ico,
  iconSize: [13, 23],
  iconAnchor: [6, 23]
});

let RotatedMarkerIcon = L.icon({
  iconUrl: route_arrow_ico,
  iconSize: [10, 12],
  iconAnchor: [5, 6],
  popupAnchor: [0, 0],
});




L.Marker.prototype.options.icon = DefaultIcon;

const url_dev = "http://localhost";
const url = "https://mkks4oc.167.99.50.224.sslip.io";

// Customizando a localização pt-BR para capitalizar os meses
const customPtBR = {
  ...ptBR,
  localize: {
    ...ptBR.localize,
    month: (n) => {
      const months = [
        'Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho',
        'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'
      ];
      return months[n];
    }
  }
};

registerLocale('pt-BR', customPtBR);


const MapView = ({ onLogout }) => {
  const [positions, setPositions] = useState([]);
  const [selectedDateRange, setSelectedDateRange] = useState([new Date(Date.now() - 2 * 3600 * 1000), new Date()]);
  const [mapCenter, setMapCenter] = useState([-23.5505, -46.6333]); // São Paulo coordinates
  const [mapZoom, setMapZoom] = useState(10);
  const [showWarning, setShowWarning] = useState(false);
  const [loading, setLoading] = useState(false);
  const markerRef = useRef(null);


  // const fetchAddress = async (lat, lon) => {
  //   try {
  //     const response = await axios.get(`https://nominatim.openstreetmap.org/reverse`, {
  //       params: {
  //         lat,
  //         lon,
  //         format: 'json',
  //         addressdetails: 1,
  //       }
  //     });
  
  //     const address = response.data.address;
  //     const road = address.road || 'Rua não disponível';
  //     const neighbourhood = address.neighbourhood || address.suburb || 'Bairro não disponível';
  //     const postcode = address.postcode || 'CEP não disponível';
  //     const houseNumber = address.house_number || 'Número não disponível';
  
  //     return `${road}, ${houseNumber} - ${neighbourhood}, CEP: ${postcode}`;
  //   } catch (error) {
  //     console.error('Error fetching address:', error);
  //     return 'Endereço não disponível';
  //   }
  // };
  
  // const fetchPositions = useCallback(async (start, end) => {
  //   const token = localStorage.getItem('token');
  
  //   // Verifica se o token ainda é válido
  //   if (token) {
  //     const decodedToken = jwtDecode(token);
  //     const currentTime = Date.now() / 1000;
  //     if (decodedToken.exp < currentTime) {
  //       onLogout(); // Se o token expirou, chama a função de logout
  //       return;
  //     }
  //   } else {
  //     onLogout(); // Se não houver token, chama a função de logout
  //     return;
  //   }
  
  //   setLoading(true);
  //   console.log("Token in map-view: " + token);
  //   try {
  //     const response = await axios.get(`${url}:3000/api/positions?start=${start}&end=${end}`, {
  //       headers: {
  //         'x-access-token': token
  //       }
  //     });
  //     console.log('Data received:', response.data);
  //     if (response.data.length === 0) {
  //       console.warn('No positions received');
  //     }
  
  //     // Adiciona o endereço a cada posição
  //     const positionsWithAddress = await Promise.all(response.data.map(async pos => {
  //       const address = await fetchAddress(pos.last_status_lat, pos.last_status_lon);
  //       return { ...pos, address };
  //     }));
  
  //     setPositions(positionsWithAddress);
  //   } catch (error) {
  //     console.error('Error fetching positions:', error);
  //   } finally {
  //     setLoading(false); // Esconde o ícone de carregamento
  //   }
  // }, [onLogout]);

  const fetchPositions = useCallback((start, end) => {
    const token = localStorage.getItem('token');

    // Verifica se o token ainda é válido
    if (token) {
      const decodedToken = jwtDecode(token);
      const currentTime = Date.now() / 1000;
      if (decodedToken.exp < currentTime) {
        onLogout(); // Se o token expirou, chama a função de logout
        return;
      }
    } else {
      onLogout(); // Se não houver token, chama a função de logout
      return;
    }

    setLoading(true);
    console.log("Token in map-view: "+token);
    axios.get(`${url}/api/positions?start=${start}&end=${end}`, {
      headers: {
        'x-access-token': token
      }
    })
    .then(response => {
      console.log('Data received:', response.data);
      if (response.data.length === 0) {
        console.warn('No positions received');
      }
      setPositions(response.data);
    })
    .catch(error => {
      console.error('Error fetching positions:', error);
    })
      .finally(() => {
        setLoading(false); // Esconde o ícone de carregamento
    });
  }, [onLogout]);

  const handleSearchClick = () => {
    if (!selectedDateRange[0] || !selectedDateRange[1]) {
      setSelectedDateRange([new Date(Date.now() - 2 * 3600 * 1000), new Date()]);
    } else {
      console.log("startTime: " +selectedDateRange[0]);
      console.log("endTime: " +selectedDateRange[1]);
      fetchPositions(selectedDateRange[0], selectedDateRange[1]);
    }
  };

  const handleUpdateClick = () => {
    const end = new Date();
    const start = new Date(end.getTime() - 2 * 3600 * 1000);
    
    setSelectedDateRange([null, null]);
    
    setTimeout(() => {
      setSelectedDateRange([start, end]);
      fetchPositions(start, end);
    }, 100);
  };

  useEffect(() => {
    handleUpdateClick();
  }, []);

  const filteredPositions = positions
    .filter(pos => pos.last_status_lat && pos.last_status_lon && !isNaN(pos.last_status_lat) && !isNaN(pos.last_status_lon))
    .map(pos => ({
      ...pos,
      last_status_lat: parseFloat(pos.last_status_lat),
      last_status_lon: parseFloat(pos.last_status_lon),
      last_position: new Date(pos.last_position).toLocaleString('pt-BR', { timeZone: 'America/Sao_Paulo', hour12: false }),
      batt_batt: pos.batt_batt,
      batt_last_charge: pos.batt_last_charge,
      last_comm: pos.last_comm,
      last_status: pos.last_status,
    }))
    .sort((a, b) => a.last_position - b.last_position)
    .filter((pos, index, self) =>
      index === self.findIndex((p) => (
        p.last_position === pos.last_position && p.last_status_lon === pos.last_status_lon
      ))
    );

  const polylinePositions = filteredPositions.map(pos => [pos.last_status_lat, pos.last_status_lon]);

  useEffect(() => {
    if (filteredPositions.length > 0) {
      const latitudes = filteredPositions.map(pos => pos.last_status_lat);
      const longitudes = filteredPositions.map(pos => pos.last_status_lon);
      const centerLat = (Math.min(...latitudes) + Math.max(...latitudes)) / 2;
      const centerLon = (Math.min(...longitudes) + Math.max(...longitudes)) / 2;
      setMapCenter([centerLat, centerLon]);
    }
  }, [filteredPositions]);

  return (
    <div className="map-view-container">
      {showWarning && (
        <div className="warning-modal">
          <div className="warning-content">
            <p>Por favor, adicione um horário válido.</p>
            <button onClick={() => setShowWarning(false)}>Fechar</button>
          </div>
        </div>
      )}

      <div className="datepicker-container">
        <DatePicker
          selected={selectedDateRange[0]}
          onChange={date => setSelectedDateRange(date)}
          startDate={selectedDateRange[0]}
          endDate={selectedDateRange[1]}
          selectsRange
          inline
          locale="pt-BR"
          maxDate={new Date()}
        />
        <hr className="divider-line" />
        <div className="time-footer">
          <div className="time-picker">
            <label>Hora de Início: </label>
            <DatePicker
              selected={selectedDateRange[0]}
              onChange={date => setSelectedDateRange([date, selectedDateRange[1]])}
              showTimeSelectOnly
              timeIntervals={15}
              timeCaption="Hora"
              dateFormat="HH:mm"
              locale="pt-BR"
            />
          </div>
          <div className="time-picker">
            <label>Hora de Fim: </label>
            <DatePicker
              selected={selectedDateRange[1]}
              onChange={date => setSelectedDateRange([selectedDateRange[0], date])}
              showTimeSelectOnly
              timeIntervals={15}
              timeCaption="Hora"
              dateFormat="HH:mm"
              locale="pt-BR"
            />
          </div>
          <LoadingSpinner loading={loading} />
          <button className='search-button' onClick={handleSearchClick} disabled={loading}> Buscar
          </button>
          <button className='update-button' onClick={handleUpdateClick} disabled={loading}> Atualizar
          </button>
          <div className="bsc-logo">
            <img src={logo} alt="BSC Logo" />
          </div>
        </div>
      </div>
      <MapContainer center={mapCenter} zoom={mapZoom} zoomControl={false} style={{ height: '100vh', width: '100%' }}>
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        />
        <ZoomControl position="topright" />
        {filteredPositions.map((pos, index) => {
          let iconToUse;
          let rotationAngle = 0;

          if (pos.last_status === "Trânsito" && index !== 0) {
            if (index < filteredPositions.length - 1) {
              const nextPos = filteredPositions[index + 1];
              const deltaX = nextPos.last_status_lon - pos.last_status_lon;
              const deltaY = nextPos.last_status_lat - pos.last_status_lat;
              rotationAngle = Math.atan2(deltaY, deltaX) * (180 / Math.PI); // Converte de radianos para graus
              rotationAngle = Math.abs(rotationAngle-180);
            }
          iconToUse = RotatedMarkerIcon; // Usa o ícone de seta para status "Transito"
          } else if (index === 0) {
            iconToUse = LastPointIcon; // Usa o ícone do último ponto
          } else {
            iconToUse = DefaultIcon; // Usa o ícone padrão para os outros pontos
          }
          

          return (
            <Marker
            key={`marker-${pos.last_position}-${index}`}
            position={[pos.last_status_lat, pos.last_status_lon]}
            icon={iconToUse}
            rotationAngle={rotationAngle}
            rotationOrigin={'center center'}
            >
              <Popup>
                SN: {pos.sn}<br />
                Latitude: {pos.last_status_lat}<br />
                Longitude: {pos.last_status_lon}<br />
                Última Posição: {pos.last_position}<br />
                Bateria: {pos.batt_last_charge}%<br />
                Última Comunicação: {new Date(pos.last_comm * 1000).toLocaleString('pt-BR', { timeZone: 'America/Sao_Paulo', hour12: false })}<br />
                Status: {pos.last_status}<br />
                {/* Endereço: {pos.address}<br /> */}
              </Popup>
            </Marker>
          );
        })}

        {polylinePositions.length > 1 && (
          <Polyline positions={polylinePositions} 
          pathOptions={{ 
          color: 'blue',
          opacity: 0.3,       // Cor da linha
          weight: 2,          // Espessura da linha
          // dashArray: '10, 10'  // Cria uma linha tracejada
        }}  
  />
        )}
      </MapContainer>
    </div>
  );
};

export default MapView;
