import React from 'react';
import { FaSpinner } from 'react-icons/fa';
import './loading-spinner.css';  // Arquivo de estilo separado para o componente

const LoadingSpinner = ({ loading }) => {
  return (
    loading ? (
      <div className="loading-spinner-container">
        <FaSpinner className="spinner" />
      </div>
    ) : null
  );
};

export default LoadingSpinner;
